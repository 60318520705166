body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  background-color: #f0f0f0;
}

.split {
  display: flex;
  width: 100%;
}

.left, .right {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.container {
  text-align: center;
}

.wheel-container {
  position: relative;
  display: inline-block;
}

.wheel {
  width: 300px;
  height: 300px;
  border: 10px solid #333;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  transition: transform 5s cubic-bezier(0.33, 1, 0.68, 1);
}

.segment {
  position: absolute;
  width: 50%;
  height: 50%;
  transform-origin: 100% 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.segment-text {
  display: block;
  text-align: center;
  font-size: 14px;
  color: white;
  transform-origin: center center;
  padding: 0px 0px 90px 0px;
}


.pointer {
      width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #333;
    position: absolute;
    bottom: 325px;
    left: 40%;
    rotate: -0deg;
    transform: translateX(-30%);
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.input-group {
  display: flex;
  align-items: center;
}

input[type="text"] {
  display: block;
  margin-right: 10px;
  padding: 5px;
  width: calc(100% - 80px); /* Adjust the width based on the button width */
}

input[type="color"] {
  display: block;
  margin-bottom: 10px;
  width: calc(100% - 12px);
}
